<template>
  <div>
    <b-card no-body>
      <b-card-body class=" pl-1 ">
        <b-row>
            <b-col>
                <div class="ml-1" style="padding-top:8px">
            <h4 class=" font-weight-700">
              Scheduled Leaves ({{total}})
            </h4>
          </div>
            </b-col>
            <b-col cols="12">
            <b-overlay
            :show="tableLoading"
            rounded="sm"
            >
            <b-table
                ref="table"
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :fields="fields"
                :items="scheduledLeaves"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                class="mobile_table_css"
                responsive
            >
              <template #cell(date)="data">
                <div style="width: 150px">
                  <b-row class="center text-primary">
                    <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                  </b-row>
                </div>
              </template>
              <template #cell(time)="data">
                <div style="width: 80px">
                  <b-row class="center text-primary">
                    <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                  </b-row>
                </div>
              </template>
                <template #cell(action)="data">
                  <div style="width: 200px">
                    <b-button
                        size="23"
                        variant="outline-white"
                        class="btn-icon shadow-sm bg-white text-primary mr-1"
                        v-b-tooltip.hover.top="'Edit'"
                        @click="toggleSideBars(data.item)"
                    >
                      <feather-icon icon="EditIcon" size="18" />
                    </b-button>

                    <b-button
                        size="23"
                        variant="outline-white"
                        class="btn-icon shadow-sm bg-white text-danger"
                        v-b-tooltip.hover.top="'Cancel'"
                        @click="cancelLeave(data.item.id)"
                    >
                      Cancel
                    </b-button>

                  </div>

                </template>

            </b-table>

            </b-overlay>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
         <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
<!--            <span class="text-muted">Showing 3 out of 3 items</span>-->
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >

            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>

            </b-pagination>

            </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <EditLeaveSidebar
        ref="EditLeaveSidebar"
        :is-edit-leave-sidebar-active="isEditLeaveSidebarActive"
        :staff-details = "staffMember"
        @toggleSideBars="toggle()"
    />
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow,
  BButton,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButtonGroup,
  BOverlay, VBTooltip
} from 'bootstrap-vue'
import leaveManagementAdmin from '@/apis/modules/leave_managment_admin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import DateDiff from 'date-diff'
import EditLeaveSidebar from '@/views/admin-leave/sidebars/editLeaveSidebar'

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay,
    EditLeaveSidebar,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data() {
    return {
      isEditLeaveSidebarActive:false,
      staffMember:{},
      total:'',
          pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableLoading: false,
      fields: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME',
        },
        {
          key: 'duration',
          label: 'DURATION',
        },
        {
          key: 'reason',
          label: 'Leave TYPE',
        },
        {
          key: 'approved_by',
          label: 'APPROVED BY',
        },
        {
          key: 'action',
          label: '',
        },


      ],
    }
    },

  mounted () {
  },

  methods:{
   async scheduledLeaves() {
     try{
       this.tableLoading = true
     const response = await leaveManagementAdmin.ScheduledLeaves(this.$route.params.id, 5 , this.currentPage)
     let scheduledLeavesArray = response.data.data.leaves.data.map((x) => ({
       id: x.id,
       user_id: x.user.id,
       date: x.is_day ? `${this.momentFormat(x.start, 'DD-MMM-YY')}` + ` - ` + `${this.momentFormat(x.end, 'DD-MMM-YY')}` : this.momentFormat(x.start, 'DD-MMM-YY'),
       time: x.is_day ? 'Full day' : `${this.momentFormat(x.start, 'HH:mm')}` + ' - ' + `${this.momentFormat(x.end, 'HH:mm')}`,
       duration: x.is_day ? `${new DateDiff(new Date(x.end), new Date(x.start)).days()} days` : `${new DateDiff(new Date(x.end), new Date(x.start)).hours()} hours`,
       reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
       reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
       sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
       approved_by: x.administrator ? x.administrator.first_name + ' ' + x.administrator.last_name : 'not approved',
       staff: x.user.first_name + ' ' + x.user.last_name,
       is_day: x.is_day,
       start: x.start,
       end: x.end,
       start_time: `${this.momentFormat(x.start, 'HH:mm')}`,
       end_time: `${this.momentFormat(x.end, 'HH:mm')}`
     }))
     this.total = response.data.data.count

       this.tableLoading = false
       const paginationResponse = response.data.data.leaves
       this.currentPage = paginationResponse.current_page
       this.pagination.totalRows = paginationResponse.total
       this.pagination.perPage = paginationResponse.per_page
       this.pagination.from = paginationResponse.from
       this.pagination.to = paginationResponse.to
       return scheduledLeavesArray
   }catch(error) {
       this.convertAndNotifyError(error)
       this.tableLoading = false
     }

   },

    toggleSideBars(data) {
      this.staffMember = data
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
    },

    async cancelLeave(id) {
     try{
       this.tableLoading = true
      const response = await leaveManagementAdmin.cancelLeave(id)
       this.tableLoading = false
      if (response.data) {
        this.$refs.table.refresh()
        this.showSuccessMessage('Leave is cancelled successfully ')
      }
    }catch(error) {
       this.convertAndNotifyError(error)
     }
    },

    async toggle() {
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
      this.$refs.table.refresh()
    },
    tableRefresh(){
      this.$refs.table.refresh()
    }

  }

}
</script>
