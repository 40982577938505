<template>
  <b-sidebar
      id="edit-leave-sidebar"
      :visible="isEditLeaveSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-leave-sidebar-active', val)"

  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Leave

        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
      >
        <!-- Form -->
      <b-overlay
      :show="formLoading"
      rounded="sm"
          >
        <div
            class="p-2"
        >
            <b-row class="mb-1">
                <b-col md="12" class="">
                  <h5 class="cursor-pointer">
                    Leave Days</h5>
                    <!-- <label>Use day off if the staff member is taking one or more full working day ({{practiceWorkingHours}} hours). Otherwise use hours off.</label> -->
                    <label> Leave is based on working hours. Please add the working hours under the profile section before adding leave. </label>
                </b-col>

                <!-- <b-col md="12">
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-radio
                        v-model="selectedLeaveType"
                        value="timeOff"
                        >
                        Hours off
                        <feather-icon
                            v-b-tooltip.hover.top="'You will be unavailable for'+'\n'+'specific hours'"
                            class="text-primary cursor-pointer"
                        style="margin-left: 10px; margin-bottom: 3px;"
                        icon="InfoIcon"
                        size="12" />
                      </b-form-radio>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-radio
                    v-model="selectedLeaveType"
                    value="dayOff"
                    >
                      Full working days off
                        <feather-icon
                            v-b-tooltip.hover.top="'You will be unavailable for'+'\n'+'more than one full day'"
                            class="text-primary cursor-pointer"
                        style="margin-left: 10px; margin-bottom: 3px;"
                        icon="InfoIcon"
                        size="12" />
                    </b-form-radio>
                    </b-col>
                  </b-row>
                </b-col> -->
            </b-row>
          <b-form>
          <b-row >
            <b-col cols="12" md="4">
              <label>Date</label>
              <validation-provider
                  ref="date"
                  #default="{ errors }"
                  :bails="false"
                  :name="'date'"
                  rules="required"
              >
              <!--  :name="'date'+index" -->

              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-form-datepicker
                        id="date"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="defaultItem.date"
                        placeholder="Select"
                        class="form-control"
                         start-weekday="1"
                         locale='en-UK'
                        trim/>
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The Date field is required</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="3">
              <label>Start Time</label>
              <validation-provider
                  ref="startTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'startTime'"
                  rules="required"
              >

              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="defaultItem.startTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The Start Time field is required</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="3">
              <label>End Time</label>
              <validation-provider
                  ref="endTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'endTime'"
                  rules="required"
              >

              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="defaultItem.endTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The End Time field is required</small>
              </validation-provider>
            </b-col>

          </b-row>
          </b-form>

          <!-- <div v-if="selectedLeaveType === 'dayOff'">
            <b-row >
              <b-col cols="12" md="4">
                <label>Start Date</label>
                <validation-provider
                    #default="{ errors }"
                    name="Start Date"
                    rules="required"
                >
                  <b-form-group>
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                        id="startdate"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="startDate"
                         start-weekday="1"
                         :min="min"
                        placeholder="Select"
                        class="form-control"
                        trim/>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <label>End Date</label>
                <validation-provider
                    #default="{ errors }"
                    name="End Date"
                    rules="required"
                >
                  <b-form-group>
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                        id="date"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="endDate"
                        start-weekday="1"
                        :min="minDate"
                        :disabled="disableEndDate"
                        class="form-control"
                        placeholder="Select"
                        trim/>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

          </div> -->
          <b-row>
            <b-col class="mt-1" cols="12">
              <label>Reason</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="leaveReason"
                      :options="leaveReasonOptions"
                      :reduce="leaveReason => leaveReason.id"
                      label="name"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="showSicknesInput">
            <b-col class="mt-1" cols="12">
              <label>Sickness Type</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="sicknessType"
                      :options="sicknessTypeOptions"
                      :reduce="sicknessType => sicknessType.id"
                      label="name"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex  mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="updateLeave()"
                :disabled="saveDisable()"
            >
              Update
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import staffBank from '@/apis/modules/staffBank'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'

export default {
  mixins: [MomentMixin, Filter],

  components: {
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    VBTooltip,
    BFormRadioGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },

  model: {
    prop: 'isEditLeaveSidebarActive',
    event: 'update:is-edit-leave-sidebar-active'
  },
  props: {
    isEditLeaveSidebarActive: {
      type: Boolean
    },
    staffDetails: {
      type: Object,
      required:true
    }
  },
  data () {
    const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth() )
      minDate.setDate(minDate.getDate())
    return {
      min: minDate,
      leaveReasonOptions:[],
      sicknessTypeOptions:[],
      showSicknesInput: false,
      formLoading:false,
      sicknessType:'',
      leaveReason:'',
      required,
      date: '',
      startTime: '',
      endTime: '',
      isActive: true,
      alphaNum,
      tableLoading: false,
      items: [],
      nextTodoId: 1,
      practiceWorkingHours:0,
      userId: '',
      selectedLeaveType:'',
      StaffMember:'',
      admin_leave:false,
      Reason:'',
      startDate:'',
      endDate:'',
      defaultItem:{
          id: 'new_' + 0,
          startTime: '',
          endTime: '',
          date: ''
      },
      leaveItems:[],
      options:[],
      disableEndDate: true,
      minDate:'',
      dataSetter:{}
    }
  },

  watch: {
    async staffDetails() {
      let setData = await this.staffDetails
      this.userId = setData.user_id
      this.selectedLeaveType = setData.is_day === 0 ? 'timeOff' : 'dayOff',
      this.defaultItem.date = this.momentFormat(setData.start, 'YYYY-MM-DD'),
      this.leaveReason = setData.reason_id ? setData.reason_id : '',
      this.sicknessType = setData.sickness_id ? setData.sickness_id : '',
      this.defaultItem.startTime = this.momentFormat(setData.start,'HH:mm'),
      this.defaultItem.endTime =  this.momentFormat(setData.end, 'HH:mm'),
      this.startDate = setData.is_day === 1? this.momentFormat(setData.start, 'YYYY-MM-DD'):'',
      this.endDate = setData.is_day === 1? this.momentFormat(setData.end, 'YYYY-MM-DD'):'',
      this.practiceWorkingHours = this.$store.state.userWorkingHoursPerDay
    },
    'startDate': function(value){
      this.minDate = new Date(value)
      if(value) this.disableEndDate = false
    },
    async leaveReason(data){
      await this.getSicknessTypes(data)
    }
  },
  methods: {
      saveDisable() {
      if(this.defaultItem.startTime < this.defaultItem.endTime ){
        return !this.isActive
      }else{
        return this.isActive === true
      }
    },
    toggle () {
      this.isEditLeaveSidebarActive = false
    },
    resetForm () {
      this.selectedLeaveType='',
      this.defaultItem.date='',
      this.leaveReason='',
      this.defaultItem.startTime='',
      this.defaultItem.endTime='',
      this.startDate='',
      this.endDate=''
    },
    async getStaff () {
      try {
        const Response = await staffBank.salariedStaffList(this.filterQuery, this.currentPage, 10, this.sortBy, this.sortDesc,'salaried-staff')
        this.options = Response.data.data.map((x) => ({
          id: x.pivot.user_id,
          first_name: x.first_name + ' ' + x.last_name,
        }))

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    async updateLeave() {
      try{
        let id = this.staffDetails.id
        let payload = {}
        if(this.sicknessType){
            payload = {
            user_id: this.userId,
            start:this.selectedLeaveType === 'timeOff'?moment(this.defaultItem.date + ` ` + this.defaultItem.startTime).utc().format('YYYY-MM-DD HH:mm:ss'):moment(this.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            end:this.selectedLeaveType === 'timeOff'?moment(this.defaultItem.date + ` ` + this.defaultItem.endTime).utc().format('YYYY-MM-DD HH:mm:ss'):moment(this.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            leave_reason_id: this.leaveReason,
            is_day: false,
            sickness_type_id: this.sicknessType
          }
        }else{
          payload = {
            user_id: this.userId,
            start:this.selectedLeaveType === 'timeOff'?moment(this.defaultItem.date + ` ` + this.defaultItem.startTime).utc().format('YYYY-MM-DD HH:mm:ss'):moment(this.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            end:this.selectedLeaveType === 'timeOff'?moment(this.defaultItem.date + ` ` + this.defaultItem.endTime).utc().format('YYYY-MM-DD HH:mm:ss'):moment(this.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            leave_reason_id: this.leaveReason,
            is_day: false
          }
        }

        const Response = await AdminLeaveAPI.updateLeaves(id,payload)
        if(Response.data.success) {
          this.showSuccessMessage('Leave Updated Successfully')
          this.resetForm()
          this.$emit('toggleSideBars')
        }else{
          if(Response.data.conflict){
            this.showLeaveConflictErrorMessage(Response)
          }
        }
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async getLeaveReasons(){
      try{
        const leaves = await AdminLeaveAPI.getLeaveReasons()
        this.leaveReasonOptions = leaves.data.data
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async getSicknessTypes(data){
      try{
        if(data === 1){
          this.formLoading = true
          const leaves = await AdminLeaveAPI.getSicknessTypes()
          this.sicknessTypeOptions = leaves.data.data
          this.showSicknesInput = true
          this.formLoading = false
        }else{
          this.formLoading = true
          this.sicknessTypeOptions = null
          this.sicknessType = ''
          this.showSicknesInput = false
          this.formLoading = false
        }
      }catch(error){
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    }
  },
  mounted () {
    this.getStaff()
    this.getLeaveReasons()
  },

}
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>

#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
