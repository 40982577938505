import api from '../axios';
const resource = '/api/mp/';

export default {
  getPracticeLeaves: (paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/practice-leaves?page=${page}&paginate=${paginate}`
    ),
  getTodaysLeaves: (paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/todays-leaves?page=${page}&paginate=${paginate}`
    ),
  getScheduledLeaves: (paginate, page, filterQuery) =>
    api.get(
      `${resource}practice-admin/leaves/scheduled-leaves?${filterQuery}&page=${page}&paginate=${paginate}`
    ),
  getRollingLeaves: (paginate, page, filterQuery) =>
    api.get(
      `${resource}practice-admin/leaves/leave-rolling?${filterQuery}&page=${page}&paginate=${paginate}`
    ),
  pendingLeaves: (paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/pending-leaves?page=${page}&paginate=${paginate}`
    ),
  summary: () => api.get(`${resource}practice-admin/leaves/summary`),
  acceptLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/approve`),
  rejectLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/reject`),
  cancelLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/cancel`),

  cancelAllBankHolidayLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/cancelAll`),

  staffList: (filterQuery,role) =>
    api.get(`${resource}practice-admin/leaves/staff-list?${filterQuery}&filter[role_name]=${role}`),

  getLeaveRequests: (id, paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/pending-leaves/user/${id}?page=${page}&paginate=${paginate}`
    ),

  ScheduledLeaves: (id, paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/scheduled-leaves/user/${id}?page=${page}&paginate=${paginate}`
    ),
  bankHolidayLeaves: (id, paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/bank-holiday-leaves/user/${id}?page=${page}&paginate=${paginate}`
    ),

  getPastLeaves: (id, paginate, page) =>
    api.get(
      `${resource}practice-admin/leaves/past-leaves/user/${id}?page=${page}&paginate=${paginate}`
    ),

  approveLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/approve`),

  manageLeaveRequest: (payload) =>
    api.post(`${resource}practice-admin/leaves/manage-leave`, payload),
  manageLeaveDetails: () =>
    api.get(`${resource}practice-admin/leaves/manage-leave/details`),
  updateLeaves: (id, payload) =>
    api.put(`${resource}practice-admin/leaves/edit-leave/${id}`, payload),

  detailsOfStaff: (id) =>
    api.get(`${resource}practice-admin/leaves/users/${id}/details`),

  userSummary: (id) =>
    api.get(`${resource}practice-admin/leaves/user-summary/${id}`),
  updateUsersNumberOfLeaves: (payload) =>
    api.put(`${resource}practice-admin/leaves/leave-count`, payload),
  getGraphDetails: () => api.get(`${resource}practice-admin/leaves/graph`),
  getGanttChartDetails: () =>
    api.get(`${resource}practice-admin/leaves/gantt-chart`),
  getUKPublicHolidays: () =>
    api.get(`${resource}practice-admin/leaves/bank-holidays`),
  getLeaveReasons: () => api.get(`${resource}practice-admin/leaves/reasons`),
  getSicknessTypes: () =>
    api.get(`${resource}practice-admin/leaves/sickness-types`),

  getUserDetails: (id) => api.get(`${resource}settings/users/${id}`),
  jobRoleNotAssignedList: (filterQuery) =>
    api.get(`${resource}practice-admin/leaves/not-assigned?${filterQuery}`),
  getAssignedJobRoles: (filterQuery) =>
    api.get(`${resource}practice-admin/leaves/assigned-roles?${filterQuery}`),
  getUserWorkingHours: (id) =>
    api.get(`/api/mp/settings/users/working-hours/${id}`),
};
